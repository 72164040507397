<template>
    <div>
        <div class="crumbs">
            <Breadcrumb v-bind:menuList="menuList"></Breadcrumb>
        </div>
        <div class="container">
            <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
                <el-tab-pane label="直播配置" name="1">
                    <el-form class="form-box" ref="aliForm" v-bind:rules="aliFormRules" v-bind:model="aliForm"
                        label-width="100px">
                        <el-form-item label="路由" prop="room_url">
                            <el-input class="form-input" type="text" auto-complete="off" v-model="aliForm.room_url"
                                placeholder="请输入路由"></el-input>
                        </el-form-item>
                        <el-form-item label>
                            <el-button type="primary" @click="saveAliForm">提 交</el-button>
                        </el-form-item>
                    </el-form>

                </el-tab-pane>
            </el-tabs>
        </div>
    </div>
</template>
<script>
    import Breadcrumb from '@/components/Breadcrumb';
    import Pagination from '@/components/Pagination';
    import { request } from '@/common/request';
    import { deal } from '@/common/main';
    export default {
        name: '',
        components: {
            Breadcrumb,
            Pagination
        },
        data() {
            return {
                activeName: '1',
                menuList: ['直播', '直播配置'],
                aliForm: {
                },
                aliFormRules: {},
                form: {
                },
                value: true,
                rules: {

                }

            }
        },
        created() {
            this.loadAliForm()
        },
        methods: {


            // tab切换
            handleClick(tab) {
                switch (tab.paneName) {
                    case '1':
                        this.loadAliForm()
                        break
                    // case '2':
                    //     this.loaddingForm()
                    //     break
                    // case '3':
                    //     this.loadversionForm()
                    //     break
                    // case '4':
                    //     this.weixingForm()
                    //     break
                    // case '5':
                    //     this.xiaochengxuForm()
                    //     break
                }
            },
            // 直播间配置
            loadAliForm() {
                this.loading = true;
                request.get('/system/live', this.aliForm).then(ret => {
                    if (ret.code == 1) {
                        this.loading = false;
                        this.aliForm = ret.data;
                    } else {
                        this.$message.error(ret.msg);
                    }
                });
            },
            // 直播间编辑
            saveAliForm() {
                this.loading = true;
                request.post('/system/live/edit', this.aliForm).then(ret => {
                    if (ret.code == 1) {
                        this.loading = false;
                        this.aliForm = ret.data;
                        this.$message.success('编辑成功');
                        this.loadAliForm()
                    } else {
                        this.$message.error(ret.msg);
                    }
                });
            },
        }
    }
</script>
<style scoped>
    .el-form-item--small.el-form-item {
        margin-top: 45px;
    }

    .el-input--small {
        margin-left: 10px;
    }

    .el-button {
        /* margin-left: 210px; */
        margin: 20px 0 100px 0;
    }

    .containet {
        background: #ffffff;
    }

    .form-box {
        width: 100%;
    }

    .form-input>>>.el-input__inner {
        width: 900px;
        border-radius: 4px;
    }
</style>